import { useState } from 'react';
import { ReactComponent as ChevronUpIcon } from '../../assets/images/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../assets/images/icons/chevron-down.svg';
import './SummarizationTabItem.scss';
import { ParsedSummarizationItem } from './SummarizationTab';

type Props = {
    aspect: ParsedSummarizationItem;
};

const SummarizationTabItem = ({ aspect }: Props) => {
    switch (aspect.type) {
        case 'recap':
            return <SummarizationTabRecap aspect={aspect} />;
        case 'issues/resolutions':
            return <SummarizationTabIssuesAndResolutions aspect={aspect} />;
        case 'narrative':
            return <SummarizationTabNarrative aspect={aspect} />;
        default:
            return <div>Unsupported Aspect</div>;
    }
};

export default SummarizationTabItem;

const SummarizationTabRecap = ({ aspect }: Props) => {
    const [isOpened, setIsOpened] = useState<boolean>(true);
    const { name, chapters } = aspect;
    const recapText = chapters[0].items[0].text;

    return (
        <div className="summarization-item summarization-item-shadow">
            <div className="summarization-item-top">
                <h3 className="summarization-item-top-name">{name}</h3>
                <button onClick={() => setIsOpened((prev) => !prev)} className="summarization-item-top-button">
                    {isOpened ? (
                        <ChevronUpIcon className="summarization-item-top-icon" />
                    ) : (
                        <ChevronDownIcon className="summarization-item-top-icon" />
                    )}
                </button>
            </div>

            {isOpened && (
                <>
                    <div className="summarization-item-separator" />
                    <p className="summarization-item-text">{recapText}</p>
                </>
            )}
        </div>
    );
};

const SummarizationTabIssuesAndResolutions = ({ aspect }: Props) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const { name, chapters } = aspect;

    return (
        <div className="summarization-item summarization-item-shadow">
            <div className="summarization-item-top">
                <h3 className="summarization-item-top-name">{name}</h3>
                <button onClick={() => setIsOpened((prev) => !prev)} className="summarization-item-top-button">
                    {isOpened ? (
                        <ChevronUpIcon className="summarization-item-top-icon" />
                    ) : (
                        <ChevronDownIcon className="summarization-item-top-icon" />
                    )}
                </button>
            </div>

            {isOpened && (
                <div>
                    <div className="summarization-item-separator" />
                    <div>
                        {chapters.map((chapter, chapterIndex) => (
                            <div key={chapterIndex}>
                                <h3 className="summarization-item-chapter-name summarization-item-chapter-name-list">{chapter.name}</h3>
                                <ul className="summarization-item-list">
                                    {chapter.items.map((item, itemIndex) => (
                                        <li key={itemIndex}>
                                            <p className="summarization-item-text">{item.text}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

const SummarizationTabNarrative = ({ aspect }: Props) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [openedChapters, setOpenedChapters] = useState<number[]>([]);

    const handleButtonClick = (index: number) => {
        if (!openedChapters.includes(index)) {
            setOpenedChapters((prev) => [...prev, index]);
        } else {
            setOpenedChapters((prev) => prev.filter((item) => item !== index));
        }
    };

    const { name, chapters } = aspect;
    const chapterTexts = chapters[0].items;
    const chapterTitles = chapters[1].items;

    return (
        <div className="summarization-item summarization-item-shadow">
            <div className="summarization-item-top">
                <h3 className="summarization-item-top-name">{name}</h3>
                <button onClick={() => setIsOpened((prev) => !prev)} className="summarization-item-top-button">
                    {isOpened ? (
                        <ChevronUpIcon className="summarization-item-top-icon" />
                    ) : (
                        <ChevronDownIcon className="summarization-item-top-icon" />
                    )}
                </button>
            </div>

            {isOpened && (
                <div className="summarization-item-merged-content">
                    <div className="summarization-item-separator" />
                    {chapterTexts.map((chapter, index) => (
                        <div key={index}>
                            <div className="summarization-item-chapter">
                                <h4 className="summarization-item-chapter-name">{chapterTitles[index].text}</h4>
                                <button onClick={() => handleButtonClick(index)} className="summarization-item-top-button">
                                    {openedChapters.includes(index) ? (
                                        <ChevronUpIcon className="summarization-item-top-icon" />
                                    ) : (
                                        <ChevronDownIcon className="summarization-item-top-icon" />
                                    )}
                                </button>
                            </div>
                            {openedChapters.includes(index) && <p className="summarization-item-text">{chapter.text}</p>}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
