import React, { Component, useContext } from 'react';

import { AuthenticationContext, AuthenticationContextInterface } from '../../context/AuthenticationContext';
import { ErrorMessageToggle } from './ErrorMessageToggle';
import css from './ErrorMessage.module.scss';
import { Button } from '@fluentui/react-components';

interface AuthenticationFailureInternalProps {
    authenticationContext: AuthenticationContextInterface;
}

class AuthenticationFailureInternal extends Component<AuthenticationFailureInternalProps, {}> {
    handleLogout = () => {
        this.props.authenticationContext.logoutRedirect();
    }

    render() {
        const logoutRendered = !this.props.authenticationContext.isSSO() ?
            <>
                <p className={css.paddingTop}>Use the button below to logout and login as a different user.</p>
                <div className={css.paddingTop}>
                    <Button 
                      appearance="primary"
                      onClick={this.handleLogout}
                    >
                      Logout
                    </Button>
                </div>
            </> : null

        const authorizationDescription = this.props.authenticationContext.isSSO() ? "single-sign-on" : null;

        return (
            <div className={css.main}>
                <p><strong>Error occurred during the {authorizationDescription} authentication.</strong></p>                
                <p>Please try again later.</p>                
                {logoutRendered}
                <ErrorMessageToggle errorMessage={this.props.authenticationContext.lastAuthenticationErrorMessage} />
            </div>
        )
    }
}

// Inject the required contexts.
export const AuthenticationFailure: React.FunctionComponent = () => {
    const authenticationContext = useContext(AuthenticationContext);
    if (!authenticationContext) {
        throw new Error("No AuthenticationContext found in React tree.");
    }

    return (
        <AuthenticationFailureInternal authenticationContext={authenticationContext} />
    )
}