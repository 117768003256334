import React, { Component } from "react";
import "./PlaySideBar.scss";
import { Redirect } from "react-router-dom";
import { urls } from "../../logic/urls";
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { RecordingInfoTab } from "./RecordingInfoTab";
import { TranscriptionTab } from "./TranscriptionTab";
import { LocationTab } from "./LocationTab";
import { PeopleTab } from "./PeopleTab";
import { Button, mergeClasses } from "@fluentui/react-components";
import { Tabs } from "./../common/Tabs";
import { SummarizationTab } from "./SummarizationTab";
import {
  Dismiss32Filled as CloseIcon,
  LineHorizontal320Filled as MenuIcon,
  ArrowSwapFilled as ArrowsIcon
} from "@fluentui/react-icons";
import {MediaActionsMenu} from '../common/menu/MediaActions';

const SIDEBAR_MIN_WIDTH = 400;
const SIDEBAR_MAX_WIDTH = window.innerWidth / 2 - 40;
const DESKTOP_MIN_SCREEN_WIDTH = 1280;

class PlaySideBarTemplate extends Component {
  constructor(props) {
    super(props);

    let initialSidebarWidth = 0;

    if (localStorage.getItem("sidebarWidth") > SIDEBAR_MAX_WIDTH) {
      initialSidebarWidth = SIDEBAR_MAX_WIDTH;
    } else {
        if(localStorage.getItem("sidebarWidth")){
            initialSidebarWidth = +Number(localStorage.getItem("sidebarWidth"));
        } else {
            initialSidebarWidth = SIDEBAR_MIN_WIDTH
            localStorage.setItem("sidebarWidth", initialSidebarWidth)
        }
    }

    this.state = {
      backToSearch: false,
      selectedTab: "a",
      width: initialSidebarWidth,
      isResizing: false,
      isDesktop: window.innerWidth > DESKTOP_MIN_SCREEN_WIDTH,
    };

    this.resizeRef = React.createRef();
  }

  componentDidMount() {
    
    document.addEventListener("mousemove", this.handleMouseMove);
    document.addEventListener("mouseup", this.handleMouseUp);
  }

  componentWillUnmount() {
    document.removeEventListener("mousemove", this.handleMouseMove);
    document.removeEventListener("mouseup", this.handleMouseUp);
  }

  handleMouseDown = (e) => {
    this.setState({ isResizing: true });
  };

  handleMouseMove = (e) => {
    if (this.state.isResizing) {
      this.setState((prevState) => {
        const newWidth = prevState.width + e.movementX;

        if (newWidth >= SIDEBAR_MIN_WIDTH && newWidth <= SIDEBAR_MAX_WIDTH) {
          this.props.triggerParentRerender(newWidth);
          localStorage.setItem("sidebarWidth", newWidth);
          return { width: newWidth };
        }

        return null;
      });
    }
  };

  handleMouseUp = () => {
    this.setState({ isResizing: false });
  };

  selectTab(selectedTab) {
    this.setState({ selectedTab });
  }

  getTabs = () => {
    const tabs = [
      {
        text: "Recording info",
        onSelect: () => this.selectTab("a"),
        id: "a",
      },
    ];

    if (this.props.textItems.length > 0) {
      tabs.push({
        text: "Transcription",
        onSelect: () => this.selectTab("b"),
        id: "b",
      });
    }

    if (this.props.mediaInfo.hasLocationTrace()) {
      tabs.push({
        text: "Location",
        onSelect: () => this.selectTab("c"),
        id: "c",
      });
    }

    if (this.props.mediaInfo.canHavePeople()) {
      tabs.push({
        text: "People",
        onSelect: () => this.selectTab("d"),
        id: "d",
      });
    }

    if (this.props.mediaInfo.canHaveSummarization()) {
      tabs.push({
        text: "Summarization",
        onSelect: () => this.selectTab("e"),
        id: "e",
      });
    }

    return tabs;
  };

  render() {
    if (!this.props.mediaInfo) {
      return null;
    }

    if (this.state.backToSearch) {
      return <Redirect push to={`${urls.search}`} />;
    }
    const { selectedTab } = this.state;
    const { handleOpenFilter, isPanelOpen } = this.props;

    return (
        <div className="sidebar-container">
        <div
          style={{ width: this.state.isDesktop ? this.state.width : "100%" }}
          className={this.props.styles.theme.container}
        >
          <div
            className="video-left-panel-header"
          >
            <div className="support-buttons">
              <Button
                appearance="primary"
                className={this.props.styles.theme.button}
                onClick={() => this.setState({ backToSearch: true })}
              >
                Back to search
              </Button>
              <div className="support-dropdown-menu">
                <Button
                  appearance="transparent"
                  className={this.props.styles.theme.button}
                  onClick={handleOpenFilter}
                  icon={isPanelOpen ? <CloseIcon /> : <MenuIcon />}
                />
              </div>
              <div className="media-menu">
                        <MediaActionsMenu mediaId={this.props.mediaInfo.mediaId} onDownload={this.props.onDownload}/>
                </div>
              <div className={`tab-list ${isPanelOpen && "active"}`}>
                <Tabs
                  selectedTabId={this.state.selectedTab}
                  tabs={this.getTabs()}
                  isBottomBorderShown
                />
              </div>
            </div>
          </div>
          <div className="video-left-panel-">
            {(function (props) {
              switch (selectedTab) {
                case "a":
                  return (
                    <RecordingInfoTab
                      mediaInfo={props.mediaInfo}
                      isPanelOpen={isPanelOpen}
                      textItems={props.textItems}
                    />
                  );
                case "b":
                  return (
                    <TranscriptionTab
                      isPanelOpen={isPanelOpen}
                      showItemAtOffsetSeconds={props.showItemAtOffsetSeconds}
                      textItems={props.textItems}
                      participants={props.participants}
                      initialSearchQuery={props.initialSearchQuery}
                      onExecuteSearch={props.onExecuteSearch}
                      onSetPositionRequest={props.onSetPositionRequest}
                    />
                  );
                case "c":
                  return (
                    <LocationTab
                      isPanelOpen={isPanelOpen}
                      timeOffSetNavigationPointer={
                        props.showItemAtOffsetSeconds
                      }
                      mediaInfo={props.mediaInfo}
                    />
                  );
                case "d":
                  return (
                    <PeopleTab
                      isPanelOpen={isPanelOpen}
                      timeOffSetNavigationPointer={
                        props.showItemAtOffsetSeconds
                      }
                      mediaInfo={props.mediaInfo}
                    />
                  );
                case "e":
                  return (
                    <SummarizationTab
                      isPanelOpen={isPanelOpen}
                      mediaInfo={props.mediaInfo}
                    />
                  );
                default:
                  return <div>Unknown tab</div>;
              }
            })(this.props)}
          </div>
        </div>
        <div
          ref={this.resizeRef}
          className={mergeClasses("resize-handler")}
          style={{ backgroundColor: this.props.styles.brandColors.mainColor }}
          onMouseDown={this.handleMouseDown}
        >
          <ArrowsIcon className="resize-handler-icon" />
        </div>
      </div>
    );
  }
}

export const PlaySideBar = connectTeamsTheme(PlaySideBarTemplate);
