import React, { Component } from 'react';
import * as misc from '../../utils/misc';
import css from './LaunchPortal.module.scss';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { Button } from '@fluentui/react-components';

const productionPortalUrl = 'https://admin.attest.cybertwice.com/';

// This page is shown in a separate tab in the Teams app and is just an easy way to open the Management Portal.
class LaunchPortalInternal extends Component {


    openPortal = () => {
        const windowFeatures = "noreferrer,noopener";
        
        let url = productionPortalUrl;

        const baseUrl = misc.getBaseUriWithoutParameters();        
        if (baseUrl.includes("d01")) {
            url = "https://app-attest-man-portal-d01.azurewebsites.net/";
        }

        window.open(url, '_blank', windowFeatures);
    }

    render() {
        return (
            <div className={css.launchPortal}>
                <Button 
                  appearance="primary" 
                  className={css.openButton} 
                  onClick={this.openPortal}
                >
                  Open management portal
                </Button>
            </div>
        )
    }
}

export const LaunchPortal = connectTeamsTheme(LaunchPortalInternal);