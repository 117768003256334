import React from 'react'

const CyberTwiceLogo = (props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 2417 417"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2,
            color:props.color
        }}
        
    >
        <rect
            id="CyberTwice-Logo-v2"
            x={0}
            y={0}
            width={2416.67}
            height={416.667}
            style={{
                fill: 'none',
            }}
        />
        <g>
            <g>
                <g>
                    <path
                        d="M2378.04,327.727l-2071.41,0c-8.903,12.345 -14.058,21.634 -25.596,31.58c-23.476,20.256 -44.446,22.334 -50.665,23.312c-0.964,0.164 -1.921,0.221 -2.87,0.221c-6.89,0 -13.138,-3.941 -15.166,-9.91l-9.339,-27.489c-1.213,-3.57 -0.735,-7.433 1.336,-10.681c2.077,-3.263 5.569,-5.648 9.688,-6.655c0.407,-0.078 0.864,-0.285 1.293,-0.378l-175.906,0c-17.05,0 -30.88,20.999 -30.88,38.427c-0,17.429 13.83,39.005 30.88,39.005l2338.64,0c17.043,0 30.845,-21.576 30.845,-39.005c-0,-17.428 -13.802,-38.427 -30.845,-38.427Z"
                        style={{
                            fill: '#ed1c24',
                            fillRule: 'nonzero',
                        }}
                    />
                    <path
                        d="M202.569,274.677c-11.824,6.112 -36.656,12.217 -67.979,12.217c-72.57,-0 -126.812,-45.824 -126.812,-130.618c-0,-80.981 54.62,-135.223 134.444,-135.223c31.709,0 52.329,6.876 61.111,11.474l-8.389,27.096c-12.238,-6.104 -30.188,-10.681 -51.579,-10.681c-60.332,-0 -100.452,38.577 -100.452,106.192c0,63.395 36.285,103.507 98.538,103.507c20.628,0 41.255,-4.198 54.635,-10.695l6.483,26.731Z"
                        style={{
                            fill: 'currentColor',
                            stroke: 'currentColor',
                            strokeWidth: '6.35px',
                        }}
                    />
                    <path
                        d="M245.673,99.6l40.119,109.226c4.562,12.224 9.146,26.739 12.216,37.827l0.764,0c3.435,-11.088 7.254,-25.211 11.838,-38.577l36.664,-108.476l35.528,-0l-50.422,131.767c-24.062,63.409 -40.483,95.867 -63.403,116.109c-16.807,14.144 -32.85,19.878 -41.247,21.391l-8.404,-28.26c8.404,-2.284 19.471,-7.646 29.41,-16.414c9.16,-7.269 20.241,-20.242 28.26,-37.435c1.527,-3.448 2.677,-5.74 2.677,-8.018c-0,-1.913 -0.771,-4.591 -2.677,-8.404l-67.98,-170.736l36.657,-0Z"
                        style={{
                            fill: 'currentColor',
                            stroke: 'currentColor',
                            strokeWidth: '6.35px',
                        }}
                    />
                    <path
                        d="M415.795,11.507l33.622,0l0,115.731l0.764,0c11.838,-20.627 33.229,-33.615 63.017,-33.615c45.831,0 77.925,38.206 77.925,93.969c0,66.458 -42.018,99.302 -83.265,99.302c-26.739,-0 -47.745,-10.31 -61.875,-34.757l-0.778,-0l-1.907,30.551l-29.023,0c1.135,-12.602 1.52,-31.315 1.52,-47.744l0,-223.437Zm33.622,197.091c0,4.205 0.379,8.403 1.128,12.223c6.498,23.298 26.368,39.334 50.423,39.334c35.528,-0 56.162,-28.638 56.162,-71.028c0,-37.442 -19.113,-69.136 -55.006,-69.136c-22.919,0 -44.31,16.036 -50.8,41.247c-1.143,4.199 -1.907,8.79 -1.907,14.13l0,33.23Z"
                        style={{
                            fill: 'currentColor',
                            stroke: 'currentColor',
                            strokeWidth: '6.35px',
                        }}
                    />
                    <path
                        d="M652.605,195.988c0.771,45.446 29.409,64.16 63.031,64.16c24.055,-0 38.956,-4.184 51.172,-9.147l6.112,23.676c-11.838,5.341 -32.465,11.838 -61.875,11.838c-56.519,0 -90.898,-37.813 -90.898,-93.19c0,-56.148 33.229,-100.08 87.078,-100.08c60.354,0 75.998,53.085 75.998,87.093c-0,6.875 -0.364,12.216 -1.128,16.036l-129.49,-0.386Zm98.167,-24.061c0.378,-21.006 -8.797,-54.62 -46.21,-54.62c-34.386,-0 -48.901,31.322 -51.578,54.62l97.788,-0Z"
                        style={{
                            fill: 'currentColor',
                            stroke: 'currentColor',
                            strokeWidth: '6.35px',
                        }}
                    />
                    <path
                        d="M825.263,155.505c-0,-21.784 -0.386,-40.498 -1.55,-57.676l29.438,-0l1.507,36.285l1.156,-0c8.404,-24.826 29.024,-40.483 51.558,-40.483c3.455,-0 6.118,0.385 9.174,1.142l0,31.323c-3.819,-0.393 -7.261,-0.771 -11.838,-0.771c-23.676,-0 -40.49,17.964 -45.067,42.782c-0.778,4.977 -1.149,10.317 -1.149,16.036l-0,98.553l-33.615,-0l0.386,-127.191Z"
                        style={{
                            fill: 'currentColor',
                            stroke: 'currentColor',
                            strokeWidth: '6.35px',
                        }}
                    />
                    <path
                        d="M999.812,53.519l-78.303,-0l-0,-28.26l190.585,-0l0,28.26l-78.674,-0l-0,229.184l-33.608,-0l0,-229.184Z"
                        style={{
                            fill: 'currentColor',
                            stroke: 'currentColor',
                            strokeWidth: '6.35px',
                        }}
                    />
                    <path
                        d="M1137.78,97.829l24.832,93.583c4.956,20.627 9.918,40.112 13.366,59.197l1.143,-0c4.198,-18.714 10.31,-38.57 16.414,-58.812l30.188,-93.968l28.26,-0l28.652,92.433c6.869,21.777 12.224,41.633 16.429,60.347l1.135,-0c3.063,-18.714 8.019,-38.192 14.123,-59.961l26.361,-92.819l33.229,-0l-59.59,184.867l-30.544,-0l-28.26,-88.236c-6.497,-20.998 -11.852,-39.34 -16.429,-61.103l-0.771,0c-4.57,22.148 -10.303,41.633 -16.8,61.489l-29.781,87.85l-30.573,-0l-55.763,-184.867l34.379,-0Z"
                        style={{
                            fill: 'currentColor',
                            stroke: 'currentColor',
                            strokeWidth: '6.35px',
                        }}
                    />
                    <path
                        d="M1421.57,63.836c-12.602,-0 -21.006,-9.932 -21.006,-21.763c0,-11.852 8.797,-21.398 21.784,-21.398c12.988,-0 21.392,9.546 21.392,21.398c-0,11.831 -8.404,21.763 -21.784,21.763l-0.386,-0Z"
                        style={{
                            fill: '#ed1c24',
                            fillRule: 'nonzero',
                        }}
                    />
                    <rect
                        x={1405.54}
                        y={97.829}
                        width={33.608}
                        height={184.867}
                        style={{
                            fill: 'currentColor',
                            stroke: 'currentColor',
                            strokeWidth: '6.35px',
                        }}
                    />
                    <path
                        d="M1626.7,276.198c-8.789,4.206 -28.267,10.696 -53.107,10.696c-55.755,-0 -92.048,-37.806 -92.048,-94.718c0,-56.913 38.963,-98.545 99.302,-98.545c19.871,-0 37.442,4.969 46.617,9.924l-7.654,25.597c-8.025,-4.206 -20.62,-8.79 -38.963,-8.79c-42.375,0 -65.301,31.709 -65.301,70.286c-0,42.782 27.51,69.136 64.166,69.136c19.099,-0 31.701,-4.591 41.262,-8.79l5.726,25.204Z"
                        style={{
                            fill: 'currentColor',
                            stroke: 'currentColor',
                            strokeWidth: '6.35px',
                        }}
                    />
                    <path
                        d="M1682.89,195.988c0.785,45.446 29.431,64.16 63.031,64.16c24.076,-0 38.97,-4.184 51.179,-9.147l6.126,23.676c-11.845,5.341 -32.479,11.838 -61.896,11.838c-56.519,0 -90.905,-37.813 -90.905,-93.19c-0,-56.148 33.25,-100.08 87.093,-100.08c60.353,0 75.997,53.085 75.997,87.093c-0,6.875 -0.364,12.216 -1.135,16.036l-129.49,-0.386Zm98.16,-24.061c0.392,-21.006 -8.775,-54.62 -46.189,-54.62c-34.385,-0 -48.908,31.322 -51.578,54.62l97.767,-0Z"
                        style={{
                            fill: 'currentColor',
                            stroke: 'currentColor',
                            strokeWidth: '6.35px',
                        }}
                    />
                </g>
                <g>
                    <path
                        d="M1911.64,120.342c15.675,-5.499 52.383,-18.381 102.538,-20.026c47.994,-1.57 90.948,4.162 156.689,37.56c7.218,3.667 10.045,8.586 10.391,9.647c0.85,3.134 1.193,6.542 1.276,7.923l-0,0.28c-0,11.589 9.394,20.982 20.981,20.982c11.588,0 20.983,-9.393 20.983,-20.982l-0.011,-1.465c-0.029,-0.891 -0.352,-8.955 -2.727,-17.715c-3.913,-14.439 -15.833,-27.928 -31.887,-36.083c-63.727,-32.375 -113.374,-44.175 -177.07,-42.088c-56.596,1.855 -97.564,16.231 -115.058,22.371c-1.753,0.615 -3.253,1.143 -4.494,1.556c-28.01,9.337 -35.305,16.459 -44.343,34.534c-5.182,10.365 -0.981,22.968 9.383,28.151c10.365,5.181 22.968,0.98 28.151,-9.383c2.052,-4.106 3.103,-6.045 3.908,-6.694c1.978,-1.593 7.419,-3.88 16.172,-6.797c1.413,-0.471 3.123,-1.069 5.118,-1.771Z"
                        style={{
                            fill: '#ed1c24',
                            fillRule: 'nonzero',
                        }}
                    />
                    <path
                        d="M2048.15,170.25c-7.975,7.533 -8.738,19.839 -2.044,28.275c3.462,7.1 10.745,11.782 18.835,11.782c0.984,0 1.978,-0.069 2.978,-0.212c11.474,-1.629 19.453,-12.25 17.823,-23.725c-0.525,-3.694 -2.327,-9.344 -7.931,-15.276c-7.957,-8.423 -21.236,-8.803 -29.661,-0.844Z"
                        style={{
                            fill: '#ed1c24',
                            fillRule: 'nonzero',
                        }}
                    />
                    <path
                        d="M1993.93,161.988c-9.679,2.687 -17.202,8.895 -21.184,17.478c-3.767,8.119 -3.96,17.83 -0.504,25.343c3.536,7.687 11.132,12.22 19.075,12.22c2.933,-0 5.915,-0.62 8.755,-1.925c5.775,-2.657 9.77,-7.607 11.399,-13.272c0.517,-1.796 1.516,-3.401 2.842,-4.718c5.215,-5.175 7.555,-12.942 5.448,-20.525c-3.101,-11.165 -14.668,-17.701 -25.831,-14.601Z"
                        style={{
                            fill: '#ed1c24',
                            fillRule: 'nonzero',
                        }}
                    />
                    <path
                        d="M2278.57,189.123c3.135,9.958 4.248,18.868 2.161,30.169c-0.004,0.024 -0.009,0.053 -0.013,0.077c-43.826,-13.716 -90.751,-8.47 -120.755,-3.536c1.693,-30.777 -8.918,-62.024 -17.559,-78.998c-5.256,-10.329 -17.891,-14.436 -28.218,-9.18c-10.326,5.257 -14.436,17.891 -9.179,28.217c8.751,17.19 19.003,52.878 9.257,75.465c-4.08,9.46 -11.325,15.433 -22.797,18.798c-25.361,7.44 -60.909,9.119 -92.774,4.383c-34.147,-5.074 -49.813,-15.235 -51.715,-19.089c-8.675,-18.341 -4.004,-46.812 14.281,-87.037c4.795,-10.548 0.13,-22.989 -10.419,-27.784c-10.55,-4.794 -22.988,-0.13 -27.784,10.419c-15.122,33.268 -33.027,82.145 -14.014,122.345c12.678,26.803 52.19,37.959 83.104,42.598c14.231,2.136 28.926,3.187 43.395,3.187c24.342,-0 48.035,-2.975 67.738,-8.754c18.106,-5.312 32.439,-15.332 42.183,-29.291c3.453,-0.644 7.164,-1.343 8.529,-1.644c24.544,-4.428 73.482,-13.065 114.933,0.187c-0.541,0.576 -1.103,1.069 -1.693,1.446c-24.652,15.782 -22.719,30.545 -22.719,30.545c16.369,-5.845 42.816,-10.899 54.8,-12.765c11.985,-1.865 33.181,-5.964 49.852,-11.067c37.405,-11.451 52.786,-39.184 52.786,-39.184c-44.939,9.586 -123.38,-39.507 -123.38,-39.507Z"
                        style={{
                            fill: '#ed1c24',
                            fillRule: 'nonzero',
                        }}
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default CyberTwiceLogo;