import React, { Component } from "react";
import { DatePicker as Datepicker } from "@fluentui/react-datepicker-compat";
import moment from "moment";

import "./DatePickerPresentation.scss";

class DatePicker extends Component {
  onDateChange = (date) => {
    if (this.props.onChange === null) {
      return;
    }
    let newDate = moment(new Date(date));
    this.props.onChange(newDate);
  };

  render() {
    const date = this.props.value.toDate();

    let minDate = null;
    let maxDate = null;
    if (this.props.minDate) {
      minDate = this.props.minDate.toDate();
    }
    if (this.props.maxDate) {
      maxDate = this.props.maxDate.toDate();
    }

    return (
      <div onClick={this.onClick}>
        <Datepicker
          className="datepicker"
          minDate={minDate}
          maxDate={maxDate}
          value={date}
          onSelectDate={newDate => this.onDateChange(newDate)}
        />
      </div>
    );
  }
}

export default DatePicker;
