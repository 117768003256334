const  getFileName = (contentUrl:any) => {
    const re = "^https://.+/([a-zA-Z0-9-_.]+)";
    const found = contentUrl.match(re);
    if (found === null || found.length !== 2)
        return "file.unknown";
    return found[1];
}

export const onDownload = (contentUrl:any) => {
    const a = document.createElement('a');
    a.download = getFileName(contentUrl);
    a.href = contentUrl;
    document.body.appendChild(a);
    a.click();
    a.remove();
}