import { Component } from 'react';
import { LocationSelector } from './LocationSelector';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
} from "@fluentui/react-components";

//onSelectedLocationChanged? (longitude: number, latitude: number): void;
export interface OnConfirmEventArgs {
  longitude: number;
  latitude: number;
  radius: number;
}
interface LocationSelectorDialogProps {
  open: boolean;
  onOpen: () => void;
  onConfirm(eventArgs: OnConfirmEventArgs): void;
  onClose(): void;
  styles: any;
}
interface LocationSelectorDialogState {
  longitude: number;
  latitude: number;
  radius: number;
}

export default class LocationSelectorDialogInternal extends Component<LocationSelectorDialogProps, LocationSelectorDialogState> {
  constructor(props: LocationSelectorDialogProps) {
    super(props);
    this.state = {
      longitude: 0,
      latitude: 0,
      radius: 1000
    }
  }

  onOpen = () => this.props.onOpen()

  onClose = () => this.props.onClose()

  onConfirm = () => {
    if (this.state.latitude === 0) {
      alert("Please select a location.");
      return;
    }
    this.props.onConfirm({
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      radius: this.state.radius
    });
  }

  onRadiusChanged = (radius: number) => {
    this.setState({ radius: radius });
  }

  onSelectedLocationChanged = (longitude: number, latitude: number) => {
    this.setState({ longitude: longitude, latitude: latitude });
  }

  getContentDialog = () => {
    return <LocationSelector onRadiusChanged={this.onRadiusChanged} onSelectedLocationChanged={this.onSelectedLocationChanged} radius={this.state.radius}></LocationSelector>
  }

  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTrigger disableButtonEnhancement>
          <Button appearance="primary" onClick={this.onOpen}>
            Select location
          </Button>
        </DialogTrigger>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Select location</DialogTitle>
            <DialogContent>
              {this.getContentDialog()}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button 
                  appearance="secondary" 
                  onClick={this.onClose} 
                  className={this.props.styles.theme.button}
                >
                  Cancel
                </Button>
              </DialogTrigger>
              <Button appearance="primary" onClick={this.onConfirm}>Select</Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    )
  }
}

export const LocationSelectorDialog = connectTeamsTheme(LocationSelectorDialogInternal as any);