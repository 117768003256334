import React from "react";
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { style } from "typestyle";

const customTabs = (props) => {
  const { styles, tabs } = props;

  const styleTabs = style({
    borderBottom: `0.06666666666666667rem solid ${styles.brandColors.secondaryColor}`,
    margin: 0,
    padding: 0,
    width: "100%",
    paddingBottom: "10px",
  });

  const styleButton = style({
    color: styles.brandColors.textColor,
    background: "none",
    border: "none",
    cursor: "pointer",
    marginRight: "1.3333rem",
    padding: "0.26666rem",
    borderBottom: "0.266rem solid transparent",
    "&:hover": {
      borderBottomColor: styles.brandColors.secondaryColor,
    },
  });

  const renderTab = (item) => {
    const selected = props.selectedTabId === item.id;

    const activeButton = style({
      borderBottom: `0.266rem solid ${styles.brandColors.secondaryColor}`,
      color: styles.brandColors.secondaryColor,
    });

    return (
      <button
        className={`${styleButton} ${selected ? activeButton : ""}`}
        tabIndex={selected ? 0 : -1}
        key={item.id}
        onMouseDown={(e) => e.preventDefault()}
        onClick={item.onSelect}
        role="tab"
      >
        {item.text}
      </button>
    );
  };

  return (
    <div className={styleTabs} role="tablist">
      {tabs.map(renderTab)}
    </div>
  );
};

export const Tabs = connectTeamsTheme(customTabs);
