import React, { Fragment } from 'react';
import './SearchResult.scss';

import { TeamsRecordingSearchResult } from './TeamsRecordingSearchResult';
import SearchResultHeader from './SearchResultHeader';
import { Pager } from './Pager';
import { resultsProcessor } from '../../logic/resultsProcessor';
import { mergeClasses } from '@fluentui/react-components';
import { connectTeamsTheme } from '../../context/connectTeamsTheme';

const SearchResultTemplate = ({searchSettings, searchResult, sorterConfig, onClick, pageUp, pageDown, loadPage, innerRef, styles}) => {
  
    if (!searchResult || !searchResult.documents) {
    return null;
  }

  if (searchResult.documents.length === 0) {
    return "No search results...";
  }


  const processedResult = resultsProcessor.processResults(searchResult.documents);

  

  return (
    <Fragment>
      <div className={mergeClasses(styles.theme.container, 'search-results-block')}>
        <div 
        className='searchresult-panel-header'>
          <SearchResultHeader searchSettings={searchSettings} searchResults={searchResult} sorterConfig={sorterConfig} />
        </div>
        <div ref={innerRef} className='searchresult-panel-body'>
          <section className="support-search-list">
            {processedResult.map(result =>  <TeamsRecordingSearchResult key={result.mediaId} item={result} onClick={onClick}/>)} 
          </section>
        </div>
        <div className={mergeClasses(styles.theme.container, 'searchresult-panel-footer')}>
          <Pager count={searchResult.totalCount} top={searchSettings.top} skip={searchSettings.skip} showPager='true' pageUp={pageUp} pageDown={pageDown} loadPage={loadPage} />
        </div>
      </div>
    </Fragment>
  );
}

export const SearchResult = connectTeamsTheme(SearchResultTemplate);
