//import * as AutoSuggest from "react-autosuggest";
import AutoSuggest, { SuggestionsFetchRequestedParams } from 'react-autosuggest'
import * as React from "react";
import { SearchContext } from '../../context/SearchContext';
import { Input } from '@fluentui/react-components';
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { autoSuggestTheme } from '../ThemeLogic';


interface SearchBoxProps {
    searchQuery: string;
    suggest(suggestQuery: string): void;
    search(searchQuery: string): void;
    queryChanged(searchQuery: string): void;
    suggestClear(): void;
    suggestionsFetchRequested(request: SuggestionsFetchRequestedParams): void;
    /** context is MS Teams Theme context. You don't need to set this property, because is't automatically set by HOC connectTeamsComponent. */
    context?: any;
    styles: any;
    suggestions: string[];
}

interface SearchBoxState {
}

class SearchBoxTemplate extends React.Component<SearchBoxProps, SearchBoxState> {
    EnterExecuted: boolean;
    constructor(props: SearchBoxProps) {
        super(props);

        this.EnterExecuted = false;
    }

    updateLastWordOfQuery(query: string, word: string) {
        const lastSpace = query.lastIndexOf(" ");
        if (lastSpace !== -1) {
            return query.slice(0, lastSpace + 1) + word + " ";
        } else {
            return word + " ";
        }
    }

    onInputChange(changeEvent: any, newValue: any) {
        if (newValue.method === "enter") {
            let searchQuery = this.props.searchQuery

            // Check if Suggestion is available
            if (newValue && newValue.newValue) {
                searchQuery = this.updateLastWordOfQuery(searchQuery, newValue.newValue);
                this.setState({ searchQuery: searchQuery });
                this.onQueryChanged(searchQuery);
            }

            this.onSearch(searchQuery);
            this.EnterExecuted = true;
        } else {
            this.EnterExecuted = false;
        }

        if (newValue.method === "up" || newValue.method === "down") {
            return;
        }
        let searchBoxText;

        if (newValue.method === "type") {
            searchBoxText = newValue.newValue;

            if (searchBoxText.trim() === "" && this.props.searchQuery.trim().length > 0) {
                this.setState({ searchQuery: "" });
                this.onQueryChanged("");

                this.onSearch("");
            } else if (searchBoxText.endsWith(" ") && !this.props.searchQuery.endsWith(" ")) {
                // Execute search when space typed so the suggestions of next word are within the search results of the previous word(s)
                this.setState({ searchQuery: searchBoxText });
                this.onQueryChanged(searchBoxText);

                // Clear suggestions
                this.onSuggest("");
                this.onSearch(searchBoxText);
            } else {
                this.setState({ searchQuery: searchBoxText });
                this.onQueryChanged(searchBoxText);

                if (changeEvent.target.selectionStart === searchBoxText.length) {
                    // only query auto complete for the last word being typed in the search bar  
                    let autoCompleteQuery;
                    const lastSpace = searchBoxText.lastIndexOf(" ");
                    if (lastSpace !== -1) {
                        autoCompleteQuery = searchBoxText.substr(lastSpace + 1);
                    } else {
                        autoCompleteQuery = searchBoxText;
                    }

                    this.onSuggest(autoCompleteQuery);
                }
            }
        } else if (newValue.method === "click") {
            const choosenSugestion = newValue.newValue;
            const currentQuery = this.props.searchQuery;

            // join choosen suggestion with current search query (which contains part of the suggestion)  
            let newQuery = this.updateLastWordOfQuery(currentQuery, choosenSugestion);

            this.setState({ searchQuery: newQuery });
            this.onQueryChanged(newQuery);
            this.onSearch(newQuery);
        }
    }

    handleKeyDown(evt: any) {
        if (evt.key === "Enter" && this.EnterExecuted === false) {
            return this.onSearch(this.props.searchQuery);
        }
    }

    getSuggestionValue(suggestion: any) {
        return suggestion;
    }

    //Executes the search event
    onSearch = (query: string) => {
        if (this.props.search) {
            this.props.search(query);
        }

    }

    //Executes the search event
    onQueryChanged = (query: string) => {
        if (this.props.queryChanged) {
            this.props.queryChanged(query);
        }

    }

    //Executes the suggest event
    onSuggest = (query: string) => {
        if (this.props.suggest) {
            this.props.suggest(query);
        }
    }

    renderInputComponent(inputProps: any) {
        const {key, ...props} = inputProps
        return (
            <div className="support-search-input width-100">
                <Input  autoFocus key={key} {...props} className="width-100" />
            </div>
        );
    }

    renderSuggestion(suggestion: any) {
        return <p className="textDecoration">{suggestion}</p>;
    }

    render() {


        // input props
        const inputProps = {
            placeholder: "Search...",
            value: this.props.searchQuery,
            onChange: this.onInputChange.bind(this),
            type: "search",
            onKeyDown: this.handleKeyDown.bind(this)
        };

        
        // check if one of the suggestions is already contained in the search query
        var filteredSuggestions = this.props.suggestions.filter((sug) => !this.props.searchQuery.includes(sug));
        const teamsContext = this.props.context;
        const autoSuggestStyle: any = autoSuggestTheme(
          this.props.styles.brandColors.backgroundColor,
          this.props.styles.brandColors.mainColor,
          teamsContext?.style
        );


        return (
            <AutoSuggest
                alwaysRenderSuggestions={false}
                suggestions={filteredSuggestions}
                inputProps={inputProps}
                getSuggestionValue={this.getSuggestionValue.bind(this)}
                onSuggestionsClearRequested={this.props.suggestClear}
                onSuggestionsFetchRequested={this.props.suggestionsFetchRequested}
                renderInputComponent={this.renderInputComponent.bind(this)}
                renderSuggestion={this.renderSuggestion.bind(this)}
                // highlightFirstSuggestion={true}
                theme={autoSuggestStyle}
            />

        );
    }
}
SearchBoxTemplate.contextType = SearchContext;
const SearchBox = connectTeamsTheme(SearchBoxTemplate as any);
export default SearchBox;


