import React, { createContext } from "react";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
} from "@fluentui/react-components";
import { microsoftTeamsLogic } from "../logic/microsoftTeamsLogic";

export const themeContext = createContext();

export class TeamsFluentUiContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      theme: teamsLightTheme,
      style: 0,
    };
  }

  componentDidMount() {
    // Register to receive an event when the Teams theme is changed.
    microsoftTeamsLogic.registerThemeChangeCallback(this.updateTheme);
  }

  componentWillUnmount() {
    microsoftTeamsLogic.unregisterThemeChangeCallback(this.updateTheme);
  }

  // Sets the correct theme type from the query string parameter.
  updateTheme = (themeStr) => {
    let theme;
    let style;
    switch (themeStr) {
      case "dark":
        theme = teamsDarkTheme;
        style = 1;
        break;
      case "contrast":
        theme = teamsHighContrastTheme;
        style = 2;
        break;
      case "default":
      default:
        theme = teamsLightTheme;
        style = 0;
    }
    this.setState({ theme, style });
  };

  render() {
    return (
      <FluentProvider theme={this.state.theme}>
        <themeContext.Provider value={this.state}>
          {this.props.children}
        </themeContext.Provider>
      </FluentProvider>
    );
  }
}
