import { Component } from "react";
import {
  ArrowDownloadRegular,
  DeleteRegular,
  DocumentSettingsRegular,
} from "@fluentui/react-icons";
import * as apiCalls from "../../../logic/apiCalls";
import { MediaActions } from "../../../types/MediaActions";
import {
  Toolbar,
  ToolbarButton,
} from "@fluentui/react-components";
import ReProcessMediaDialog from "../reprocessmedia/ReProcessMediaDialog";
import debounce from "lodash.debounce";

interface MediaActionsMenuProps {
  mediaId: string;
  onDownload:()=>void;
  isHovered:boolean
  location:string
}

interface MediaActionsMenuState {
  mediaActions?: MediaActions;
  isReProcessDialogOpen: boolean;
}

const items = [
  {
    disabled: true,
    key: "reprocess",    
    title: "Reprocess",
    icon: <DocumentSettingsRegular />,
    onClick: (e: any) => {
      alert("reprocess");
    },
  },
  {
    disabled: true,
    title: "Download",
    key: "download",
    icon: <ArrowDownloadRegular />,
    onClick: (e: any) => {
      
    },
  },
  {
    disabled: true,
    title: "Delete",
    key: "delete",
    icon: <DeleteRegular />,
    onClick: (e: any) => {
      alert("Delete");
    },
  },
];

export class MediaActionsMenu extends Component<
  MediaActionsMenuProps,
  MediaActionsMenuState
> {
  constructor(props: MediaActionsMenuProps) {
    super(props);
    this.state = {
      mediaActions: undefined,
      isReProcessDialogOpen: false,
    };
  }

  openDialog = () => this.setState({ isReProcessDialogOpen: true });

  onClose = () => {
    this.setState({
      isReProcessDialogOpen: false,
    });
  };
  handleOnDownload=()=>{
    this.props.onDownload()
  }

  async componentDidMount() {
    if (!this.state.mediaActions && this.props.isHovered && this.props.location.includes("search")){
        this.debouncedRequest()
      }
      else{
        this.getMediaActions()
      }
  }

  async getMediaActions() {
    const mediaActions = await apiCalls.getMediaActions(this.props.mediaId);
    this.setState({ mediaActions: mediaActions });
  }
  debouncedRequest = debounce(this.getMediaActions,1000)

  render() {
    if (!this.state.mediaActions) {
      items.forEach((element) => {
        element.disabled = true;
      });
    } else {
      items.forEach((element) => {
        let disabled = this.state.mediaActions?.actions.find(
          (action) => action.name.toLocaleLowerCase() === element.key
        )?.allowed
          ? false
          : true;
        element.disabled = disabled;

        if (element.key === "reprocess" && !disabled) {
          element.onClick = this.openDialog;
        }
        if (element.key === "download" && !disabled) {
            element.onClick = this.handleOnDownload;
          }
      });
    }

    return (
      <>
        <Toolbar aria-label="Compose Editor">
          {items.map(
            ({ key, disabled, onClick, icon, title }) =>
              !disabled && (
                <ToolbarButton
                  appearance="subtle"
                  as="button"
                  onClick={onClick}
                  disabled={disabled}
                  key={key}
                  icon={icon}
                  title={title}
                />
              )
          )}
        </Toolbar>
        <ReProcessMediaDialog mediaId={this.props.mediaId} open={this.state.isReProcessDialogOpen} onClose={() => this.setState({isReProcessDialogOpen: false})} />        
      </>
    );
  }
}
