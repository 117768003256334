import React, { Component } from 'react';
import { TextItem, Participant } from '../../types/transcription';
import { durationToTranscriptionDisplayString } from '../../logic/timeDisplay';
import { createContent } from '../../logic/contentCreation';
import { Chat, ChatMessage } from '@fluentui-contrib/react-chat';
import { Avatar } from '@fluentui/react-components';

import css from './TranscriptionListRow.module.scss';

export type TranscriptionRowData = {
  textItem: TextItem,
  participant: Participant,
  isSelected: boolean,
  isAttached: boolean,
  showParticipant: boolean,
}

type Props = {
  index: number,
  style: any,
  data: TranscriptionRowData,
  onSetPositionRequest: (position: number) => void,
  heightCallback: heightCallback,
  searchQuery: string;
}

type heightCallback = (index: number, height: number) => void;

export class TranscriptionListRow extends Component<Props, {}> {
  divRef: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount() {
    this.reportHeight();
  }

  componentDidUpdate() {
    this.reportHeight();
  }

  // Method reports the rendered row height in pixels back to the TranscriptionList component. 
  reportHeight = () => {
    const divElement = this.divRef.current;
    if (!divElement) {
      return;
    }

    const scrollHeight = divElement.scrollHeight;

    this.props.heightCallback(this.props.index, scrollHeight);
  }

  render() {
    const { textItem, participant, isSelected, isAttached, showParticipant } = this.props.data;

    // The 'style' object passed from the VariableSizeList parent component contains a height property which is
    // ignored so that the row uses all the height it intrinsically needs.  
    const { style } = this.props;
    const styleCopy = { ...style };
    delete styleCopy.height;

  
    const chatMessage = {
      messageId: 1,
      messageContent: createContent(textItem, css.highlight, this.props.onSetPositionRequest),
      participant: participant,
      timestamp: durationToTranscriptionDisplayString(textItem.offsetSeconds),
      showParticipant,
      isAttached,
      isHighlighted: isSelected,
    }

    return (
      <div ref={this.divRef} style={styleCopy}>
        <Chat>
          <ChatMessage
            author={chatMessage.participant.displayname || 'A'}
            timestamp={chatMessage.timestamp}
            avatar={
              chatMessage.showParticipant
                ? <Avatar badge={{ status: 'available' }} name={chatMessage.participant.displayname} />
                : null
            }
            attached={chatMessage.isAttached ? 'center' : 'top'}
            key={`message-id-${chatMessage.messageId}`}
          >
            {chatMessage.messageContent}
          </ChatMessage>
        </Chat>
      </div>
    )
  }
}
