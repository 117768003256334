import React, { useContext } from "react";
import { themeContext } from "./TeamsFluentUiContextProvider";
import { 
  useLightThemeStyles, 
  useDarkThemeStyles, 
  useHighContrastThemeStyles,
  getThemeBrandColors,
} from "../components/ThemeLogic";

export const connectTeamsTheme = (Component) => (props) => {
  const currentTheme = useContext(themeContext);

  const lightThemeStyles = useLightThemeStyles();
  const darkThemeStyles = useDarkThemeStyles();
  const highContrastThemeStyles = useHighContrastThemeStyles();
  const themeBrandColors = getThemeBrandColors(currentTheme.style);

  const themeStyles = {
    '0': lightThemeStyles,
    '1': darkThemeStyles,
    '2': highContrastThemeStyles, 
  };

  const styles = {
    theme: themeStyles[currentTheme.style] || themeStyles['0'],
    brandColors: themeBrandColors
  }

  return <Component
    context={currentTheme}
    styles={styles} 
    {...props}
  />
}