import React, { Component, useContext } from 'react';
import { AuthorizationContext, AuthorizationContextInterface } from '../../context/AuthorizationContext';
import { Anchor } from "./../common/Anchor";

import css from './ErrorMessage.module.scss';
import { Button } from '@fluentui/react-components';

interface TenantUnknownInternalProps {
    authorizationContext: AuthorizationContextInterface    
}

class TenantUnknownInternal extends Component<TenantUnknownInternalProps, {}> {
    handleLogout = () => {
        this.props.authorizationContext.logoutRedirect();
    }

    render() {

        const marketplaceLinkRendered = this.props.authorizationContext.isOnMobile() ? <></> : <>
            <br/>
            <p>The Attest service can be purchased here: <Anchor href="https://azuremarketplace.microsoft.com/en-us/marketplace/apps/cybertwicebv1586872140395.attest_teams" target="_blank" rel="noopener noreferrer">Azure Marketplace</Anchor></p>
        </>

        const logoutButtonRendered = !this.props.authorizationContext.isSSO() ? 
        <>
            <br/>
            <p className={css.paddingTop}>In case you accidentally logged-in with a wrong account, use the button below to logout.</p>
            <div className={css.paddingTop}>
                <Button appearance="primary" onClick={this.handleLogout}>
                  Logout
                </Button>
            </div>
        </> : 
        <></>

        return (
            <div className={css.main}>
                <p><strong>Your organization has not signed up yet for the Attest service.</strong></p>
                <p>Please contact your IT administrator.</p>
                {marketplaceLinkRendered}
                {logoutButtonRendered}
            </div>
        )
    }
}

// Inject the required contexts.
export const TenantUnknown: React.FunctionComponent = () => {
    const authorizationContext = useContext(AuthorizationContext);
    if (!authorizationContext) {
        throw new Error("No AuthorizationContext found in React tree.");
    }

    return (
        <TenantUnknownInternal authorizationContext={authorizationContext} />
    )
}