import React from "react";
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { style } from "typestyle";

const customAnchor = (props) => {
  const { styles, ...rest } = props;
  const color = styles.brandColors.secondaryColor;

  const anchorStyle = style({
    color,
    outline: "none",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    "&:active": {
      textDecoration: "underline",
    },
    "&:focus": {
      textDecoration: "underline",
    },
  });

  return (
    <a className={anchorStyle} {...rest}>
      {props.children}
    </a>
  );
};

export const Anchor = connectTeamsTheme(customAnchor);
