import { makeStyles } from "@fluentui/react-components";
import {
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
} from "@fluentui/react-components";

const defaultStyles = {
  body: {
    backgroundColor: teamsLightTheme.colorNeutralBackground4,
    color: teamsLightTheme.colorNeutralForeground1,
  },
  button: {
    border: "none",
    boxShadow: "none",
  },
  container: {
    backgroundColor: teamsLightTheme.colorNeutralBackground1,
  },
  icon: {
    color: '#616161',
  },
  sliderThumb: {
    backgroundColor: teamsLightTheme.colorBrandBackground,
  }
};

const useLightThemeStyles = makeStyles({
  ...defaultStyles,
});

const useDarkThemeStyles = makeStyles({
  ...defaultStyles,
  body: {
    backgroundColor: teamsDarkTheme.colorNeutralBackground4,
    color: teamsDarkTheme.colorNeutralForeground1,
  },
  container: {
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
  },
  icon: {
    color: '#ADADAD',
  },
  sliderThumb: {
    backgroundColor: teamsDarkTheme.colorBrandBackground,
  }
});

const useHighContrastThemeStyles = makeStyles({
  ...defaultStyles,
  body: {
    backgroundColor: teamsHighContrastTheme.colorNeutralBackground4,
    color: teamsHighContrastTheme.colorNeutralForeground1,
  },
  container: {
    backgroundColor: teamsHighContrastTheme.colorNeutralBackground1,
    border: `0.13rem solid`,
  },
  icon: {
    color: '#FFFFFF',
  },
  sliderThumb: {
    backgroundColor: teamsHighContrastTheme.colorBrandBackground,
  }
});

const getThemeBrandColors = (style) => {
  const brandColors = {};

  switch (style) {
    case 1:
      brandColors.mainColor = teamsDarkTheme.colorBrandBackground;
      brandColors.secondaryColor = teamsDarkTheme.colorBrandBackgroundHover;
      brandColors.paginationButton = teamsDarkTheme.colorBrandBackground;
      brandColors.textColor = teamsDarkTheme.colorNeutralForeground1;
      brandColors.backgroundColor = teamsDarkTheme.colorNeutralBackground4;
      brandColors.borderColor = '#ADADAD';
      break;
    case 2:
      brandColors.mainColor = teamsHighContrastTheme.colorBrandBackground;
      brandColors.secondaryColor =
        teamsHighContrastTheme.colorBrandBackgroundHover;
      brandColors.paginationButton = teamsHighContrastTheme.colorBrandBackground;
      brandColors.textColor = teamsHighContrastTheme.colorNeutralForeground1;
      brandColors.backgroundColor = teamsHighContrastTheme.colorNeutralBackground4;
      brandColors.borderColor = '#FFFFFF';
      break;
    case 0:
    default:
      brandColors.mainColor = teamsLightTheme.colorBrandBackground;
      brandColors.secondaryColor = teamsLightTheme.colorBrandBackgroundHover;
      brandColors.paginationButton = teamsLightTheme.colorNeutralBackground1Hover;
      brandColors.textColor = teamsLightTheme.colorNeutralForeground1;
      brandColors.backgroundColor = teamsLightTheme.colorNeutralBackground4;
      brandColors.borderColor = '#616161';
  }

  return brandColors;
};

/**
 * Object containing all the logic for autosuggest dropdown
 */

const autoSuggestTheme = (background, highlightColor, style) => {
  return {
    suggestionsContainer: {
        backgroundColor: background,
        position: 'absolute',
        boxShadow: style === 0 ? '0px 0.13333333333333333rem 0.8rem 0px rgba(22, 35, 58, 0.36)' : style === 1 ? '0px 0.13333333333333333rem 0.8rem 0px rgba(0, 0, 0, 0.4)' : null,
        border: style === 2 ? '0.06666666666666667rem solid #FFFFFF' : '0',
        borderRadius: '0.2rem',
        zIndex: '1',
    },
    suggestion: {
      padding: '5px 10px 5px 10px',
        cursor: 'pointer',
        ':lastChild': {
            marginBottom: '0',
        },
    },
    suggestionHighlighted: {
      color: style === 2 ? '#000000' : '#FFFFFF',
      backgroundColor: highlightColor,
    }
  };
}

export {
  useLightThemeStyles,
  useDarkThemeStyles,
  useHighContrastThemeStyles,
  getThemeBrandColors,
  autoSuggestTheme,
};
