import {} from './globals'
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import './assets/styles/index.scss';
import './logic/backgroundColorUpdater';

import { appConfig } from './logic/appConfigProvider';
import log from 'loglevel';

log.setDefaultLevel(appConfig.logLevel);


const root = createRoot(document.getElementById("root"));
root.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>
)



window.addEventListener('load', () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then((registration) => { if (registration) registration.unregister(); });
    }
});