import React, { Fragment, Component } from 'react';
import './Login.scss'
import { AuthorizationContext } from '../../context/AuthorizationContext';
import { MagicPixel } from '../MagicPixel';
import { isMobile } from '../../logic/platformDetection';
import { urls } from '../../logic/urls';
import { mergeClasses, Button } from '@fluentui/react-components'
import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { Anchor } from "./../common/Anchor";
import CyberTwiceLogo from '../common/CyberTwiceLogo'


class LoginInteral extends Component {
  static contextType = AuthorizationContext;
  
  login = () => {
    this.context.loginRedirect(urls.search);
  }
  
  render() {
    const signupTextRendered = !isMobile() ? <>
          <p className="small-text">When your organization has not signed up yet for the ATTEST service please contact <Anchor href="https://www.cybertwice.com/contact.html" target="_blank" rel="noopener noreferrer">CyberTwice</Anchor> to get a subscription.</p>
          <p>&nbsp;</p>
      </> : null;  
    const color = this.props.context.style === 0 ?  "#403f41" : "#FFFFFF"
    return (
      <Fragment>
        <MagicPixel debugChanged={() => this.forceUpdate()} />
        <div className={this.props.styles.theme.body}>
          <div className={mergeClasses(this.props.styles.theme.container, 'content-height login-container')}>
            <div className='main'>
              <section className="support-login">
                <section className="content-login flex--wrap">
                  <section className="control-center">
                    <header>
                      <div className="company-logo" />
                      <div className="company-text">ATTEST Replay</div>
                    </header>
                    <section className="login flex--wrap">
                      <p className="title">Log in</p>
                      <p className="sub-text">with your office 365 account</p>
                        <Button
                          size="large" 
                          appearance="primary"
                          className={mergeClasses(this.props.styles.theme.button, 'login-button')}
                          onClick={this.login}
                        >
                          Log in
                        </Button>
                      {signupTextRendered}
                      <p className="small-text">By using ATTEST Replay
                        you agree to the <Anchor href={urls.subscriptionAgreement} target="_blank" rel="noopener noreferrer">Subscription Agreement</Anchor> and
                        the <Anchor href={urls.subscriptionDetails} target="_blank" rel="noopener noreferrer">ATTEST Subscription Details</Anchor>.
                      </p>
                    </section>
                  </section>
                  <footer>
                    <div className="red-line" />
                    <p>by</p>
                    <div>
                      <CyberTwiceLogo color={color}/>
                      </div>
                  </footer>
                </section>
              </section>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export const Login = connectTeamsTheme(LoginInteral);