import React, { Component } from 'react';
import { connectTeamsTheme } from "./../../../context/connectTeamsTheme";
import { 
  ChevronDown32Filled as ChevronDownIcon, 
  ChevronRight32Filled as ChevronRightIcon 
} from '@fluentui/react-icons';
import { Button, Checkbox  } from '@fluentui/react-components';

  class IntFacetTemplate extends Component {
    constructor() {
        super();

        this.state = {
            isExpanded: false,
        }
    }
    handleSectionExpand = () => this.setState(prevState => ({ isExpanded: !prevState.isExpanded }))
    render() {
        // The facetData is an object of which the properties name (key) is what is displayed as an entry
        // and the value contains the count of this entry.
        const facetData = this.props.facetData;
        // The facetSelection is an array of values that should be shown as checked.
        const facetSelection = this.props.facetSelection || [];

        if (!facetData || Object.keys(facetData).length < 1) {
            return null;
        }

        let detectedCheckedCheckbox = false;

        let checkboxes = Object.keys(facetData).map((valueKey, index) => {
            const value = facetData[valueKey];

            // Don't display facets that have value 0
            if (!(value.count) || value.count <= 0) {
                return null;
            }

            const countDisplay = value.count ? `(${value.count})` : "";
            const isChecked = facetSelection.some(item => item === valueKey);

            if (isChecked) {
                detectedCheckedCheckbox = true;
            }
            return (
                <li key={index + 1}>
                    <Checkbox 
                    // value={valueKey} 
                    label={`${valueKey} ${countDisplay}`} 
                    checked={isChecked} 
                    onClick={() => this.props.onChangeFacet(this.props.name, valueKey)} />
                </li>
            );
        });

        if (checkboxes.length === 1 && !detectedCheckedCheckbox)
        {
            return null;
        }
        const { handleSectionExpand, state } = this;
        // is isExpanded we display the section
        const { isExpanded } = state;
        const { style } = this.props.context;
        return <section className={`search-fragment ${isExpanded ? 'expanded' : ''} ${style === 0 ? 'light' : ''}`}>
            <header className={`${facetSelection.length > 0 && ('active')}`}  onClick={() => handleSectionExpand()}>
                <Button
                    appearance="transparent"
                    icon={isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
                />
                {this.props.displayName}
            </header>
            <div className="support-content">
                <ul>
                    {checkboxes}
                </ul>
            </div>
        </section>;
    }    
}
const IntFacet = connectTeamsTheme(IntFacetTemplate);
export default IntFacet;