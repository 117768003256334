import { Component  } from 'react';
import css from './ErrorMessage.module.scss';
import { Button, mergeClasses } from '@fluentui/react-components';
import { connectTeamsTheme } from '../../context/connectTeamsTheme';

interface ErrorMessageToggleProps {
    errorMessage: string;
    styles: any;
}

class ErrorMessageToggleTemplate extends Component<ErrorMessageToggleProps, {}> {
    state = {
        showDetails: false
    }
    
    handleShowDetailsClick = () => {
        this.setState({
            showDetails: !this.state.showDetails
        });
    }

    render() {
        const detailsMessageRendered = this.state.showDetails ? 
            <div className={mergeClasses(this.props.styles.theme.container, css.detailMessage)}>
                <pre>{this.props.errorMessage}</pre>
            </div> : 
        <></>

        return (
            <div className={css.details}>
                <Button appearance="primary" onClick={this.handleShowDetailsClick}>
                  Show details
                </Button>
                <div className={css.paddingTopLarge}>
                    {detailsMessageRendered}
                </div>
            </div>
        )
    }
}

export const ErrorMessageToggle = connectTeamsTheme(ErrorMessageToggleTemplate);