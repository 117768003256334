import { Component } from 'react';
import { 
  ChevronDown32Filled as ChevronDownIcon, 
  ChevronRight32Filled as ChevronRightIcon 
} from '@fluentui/react-icons';
import LocationSelectorDialog, { OnConfirmEventArgs } from '../../map/LocationSelectorDialog';
import { GeographyPointsFacetValue } from './Types';
import { connectTeamsTheme } from "./../../../context/connectTeamsTheme";
import { Button } from '@fluentui/react-components';
 

interface GeographyPointsFacetTemplateProps {
    name: string; //The field name
    facetData: any;
    facetSelection: GeographyPointsFacetValue | null;
    context?: any;
    styles: any;
    displayName: string;
    onChangeFacet(facetName: string, facetValue: GeographyPointsFacetValue ) : void;
}
interface GeographyPointsFacetTemplateState {
    isExpanded: boolean;
    isDialogOpen: boolean;
    longitude: number;
    latitude: number;
    radius: number;
}

class GeographyPointsFacetTemplate extends Component<GeographyPointsFacetTemplateProps, GeographyPointsFacetTemplateState> {
    constructor(props: GeographyPointsFacetTemplateProps) {
        super(props);

        this.state = {
            isExpanded: false,
            isDialogOpen: false,
            longitude: 0,
            latitude: 0,
            radius: 0
        }
    }

    handleSectionExpand = () => this.setState(prevState => ({ isExpanded: !prevState.isExpanded }))

    openDialog = () => this.setState({isDialogOpen: true}) 

    onConfirm = (eventArgs: OnConfirmEventArgs) => {
        this.setState({
            isDialogOpen: false,
            latitude: eventArgs.latitude,
            longitude: eventArgs.longitude,
            radius: eventArgs.radius
        });
        this.props.onChangeFacet(this.props.name, eventArgs);
    }

    onClose = () => {
        this.setState({
            isDialogOpen: false,

        });
    }

    render() {        
        // The facetSelection is an array of strings that should be shown as checked.
        let facetSelection: GeographyPointsFacetValue | null  = this.props.facetSelection ;
        
        const { handleSectionExpand, state } = this;
        // is isExpanded we display the section
        const { isExpanded } = state;
        
        return (
            <section className={`search-fragment ${isExpanded ? 'expanded' : ''}`}>
                <header className={`${facetSelection && ('active')}`} onClick={() => handleSectionExpand()}>
                    <Button
                        appearance="transparent"
                        icon={isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
                    />
                    {this.props.displayName}
                </header>
                <div className="support-content">
                    {facetSelection &&
                        <div>
                            <div>Latitude: {facetSelection.latitude}</div>
                            <div>Longitude: {facetSelection.longitude}</div>
                            <div>Radius: {facetSelection.radius}</div>
                            <div></div>
                        </div>
                    }
                    <LocationSelectorDialog 
                      onOpen={this.openDialog} 
                      open={this.state.isDialogOpen} 
                      onConfirm={this.onConfirm} 
                      onClose={this.onClose} 
                      styles={this.props.styles}
                    />
                </div>
            </section>
        )
    }    
}
const GeographyPointsFacet = connectTeamsTheme(GeographyPointsFacetTemplate);
export default GeographyPointsFacet; 


