import { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthenticationContextProvider } from './context/AuthenticationContext';
import { AuthorizationContextProvider } from './context/AuthorizationContext';
import { SearchContextProvider } from './context/SearchContext';
import { Home } from './components/Home';
import { Login } from './components/login/Login';
import { PageNotFound } from './components/login/PageNotFound';
import { Search } from './components/search/Search';
import { Play } from './components/play/Play';
import { TestPage } from './components/transcriptionTest/TestPage';
import { PostLogin } from './components/login/PostLogin';
import { ProtectedRoute } from './routers/ProtectedRoute';
import { LaunchPortal } from './components/login/LaunchPortal';
import { urls } from './logic/urls';
import { TimelineContextProvider } from './context/TimelineContext';
import { TeamsFluentUiContextProvider } from './context/TeamsFluentUiContextProvider';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') ?? undefined;

class App extends Component {
    render() {
        return (
            <AuthenticationContextProvider>
                <AuthorizationContextProvider>
                    <SearchContextProvider>
                        <TimelineContextProvider>
                          <TeamsFluentUiContextProvider>
                                  <Router basename={baseUrl}>
                                      <Switch>
                                          <ProtectedRoute exact allowedPermissions={[]} path={urls.home} component={Home} />
                                          <Route exact path={urls.launchPortal} component={LaunchPortal} />
                                          <Route exact path={urls.login} component={Login} />
                                          <Route exact path={urls.postLogin} component={PostLogin} />
                                          <Route exact path={urls.testPage} component={TestPage} />
                                          <ProtectedRoute exact autoRedirectToMicrosoft allowedPermissions={["SearchAllCalls", "SearchOwnCalls"]} path={urls.search} component={Search} />
                                          <ProtectedRoute exact autoRedirectToMicrosoft allowedPermissions={["PlayAllCalls", "PlayOwnCalls"]} path={urls.play} component={Play} />
                                          <Route path="*" component={PageNotFound} />
                                      </Switch>
                                  </Router>
                          </TeamsFluentUiContextProvider>
                        </TimelineContextProvider>
                    </SearchContextProvider>
                </AuthorizationContextProvider>
            </AuthenticationContextProvider>
        );
    }
}

export default App;
