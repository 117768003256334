import { Component } from 'react'
import { FaceButtonListItem, FaceButtonListItemClickedEventArgs } from './FaceButtonListItem';
import { Face } from './types';
import { connectTeamsTheme } from '../../../context/connectTeamsTheme';
import { mergeClasses } from '@fluentui/react-components';

export interface FaceButtonListItemSelectedEventArgs{
    id: string | undefined;
}
interface FacesButtonListProps {
    imagesUrl: string
    faces: Face[];
    styles: any;
    itemSelected?(e: FaceButtonListItemSelectedEventArgs): void;
}
interface FacesButtonListState {
    selectedItem?: string
}

interface FacesButtonListItemsProps {
    faces: Face[];    
    handleItemClick(e: FaceButtonListItemClickedEventArgs): void;
    selectedItem?: string
    context?: any
}


function FacesButtonListItems(props: FacesButtonListItemsProps) {    
    const persons = props.faces;
    const listItems = persons.map((person) => {
        return (
           <FaceButtonListItem key={person.id} faceDate={person} selected={props.selectedItem === person.id} handleClick={props.handleItemClick}  />
        );
    });

    return (
        <>
            {listItems}
        </>      
    );
  }

export class FacesButtonListTemplate extends Component<FacesButtonListProps, FacesButtonListState> {
    state = {
        selectedItem: undefined
    }

    handleItemClick = (e: FaceButtonListItemClickedEventArgs) => {
        let selectedItemId: string | undefined = undefined;

        if (this.state.selectedItem !== e.id){
            selectedItemId = e.id;
        }
        
        this.setState({
            selectedItem: selectedItemId
        });

        if (this.props.itemSelected){
            this.props.itemSelected({ id: selectedItemId} )
        }
    };

    render() {
        const { selectedItem } = this.state;
        const selectedFace = this.props.faces.find(f => f.id === selectedItem);

        return (            
            <>
                {selectedFace &&
                    <>
                        <div className={mergeClasses(this.props.styles.theme.container, 'peoples')}>
                            <FaceButtonListItem key={selectedFace.id} faceDate={selectedFace ?? undefined} selected={false} />
                            <p>{selectedFace.title}</p>
                        </div>
                    </>
                }
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <FacesButtonListItems faces={this.props.faces} handleItemClick={this.handleItemClick} selectedItem={selectedItem} />
                </div> 
            </>
        )
    }   
}

export const FacesButtonList = connectTeamsTheme(FacesButtonListTemplate);