import React, { Component } from 'react';
import { Avatar, Menu, MenuTrigger, MenuList, MenuItem, MenuPopover } from '@fluentui/react-components';
import { AuthorizationContext } from '../context/AuthorizationContext';
import css from './UserLogin.module.scss';

export class UserLogin extends Component<{}, {}> {
    context!: React.ContextType<typeof AuthorizationContext>;
    static contextType = AuthorizationContext;

    handleClick = () => {
        this.context!.logoutRedirect();
    };

    render() {
        var inputProps: any = {
            className: css.Avatar,
            name: this.context!.displayName,
            'aria-label': 'Logged-in user',
        };

        if (this.context!.profilePicture) {
            inputProps['image'] = { src: this.context!.profilePicture };
        }

        const menuItems = [
            {
                key: '1',
                content: 'Sign out',
                onClick: this.handleClick,
            },
        ];

        return (
            <Menu>
                <MenuTrigger>
                    <Avatar {...inputProps} />
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        {menuItems.map((item) => (
                            <MenuItem key={item.key} onClick={item.onClick}>
                                {item.content}
                            </MenuItem>
                        ))}
                    </MenuList>
                </MenuPopover>
            </Menu>
        );
    }
}
