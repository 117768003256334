import * as React from "react";
import "./Pager.scss";

import { connectTeamsTheme } from "./../../context/connectTeamsTheme";
import { Button } from "@fluentui/react-components";
import { style } from "typestyle";
import {
  ChevronLeft32Filled as ChevronLeft,
  ChevronRight32Filled as ChevronRight,
} from "@fluentui/react-icons";

class PagerTemplate extends React.Component {
  render() {
    const { count, top, skip, showPager, pageUp, pageDown, loadPage } =
      this.props;

    const css = {
      pagerNav: "pager-nav",
      pagerList: "pager-list",
      pagerItem: "pager-page-item",
      pagerItemDisabled: "pager-page-item-disabled",
      pagerLink: "pager-page-link",
      pagerItemActive: "pager-page-item-active",
      screenReaderOnly: "screen-reader-only",
    };

    const maxSkip = 100000;
    const maxPage =
      count > 0 ? Math.ceil(count / top) : Math.ceil(maxSkip / top) + 1;
    const activePage = skip / top + 1;
    const enablePrevious = activePage > 1;
    const enableNext = activePage < maxPage;

    let onPreviousClick = () => {
      return enablePrevious && pageDown();
    };

    let getCssClass = (page, isActive, isDisabled) => {
      let cssClass = isActive ? css.pagerItemActive : css.pagerItem;
      cssClass = isDisabled ? css.pagerItemDisabled : cssClass;
      return cssClass;
    };

    let generateButton = (
      page,
      isActive = false,
      index,
      isDisabled = false
    ) => {
      const pagesSeparator = page === "...";
      let onPageClick = () => {
        return !pagesSeparator ? loadPage(page) : null;
      };

      const buttonColor = style({
        backgroundColor: isActive
          ? this.props.styles.brandColors.paginationButton
          : null,
        "&:hover": {
          backgroundColor: this.props.styles.brandColors.paginationButton,
        },
      });

      return (
        <li key={index}>
          <div
            className={`${buttonColor} ${getCssClass(
              page,
              isActive,
              isDisabled
            )}`}
            onClick={onPageClick}
          >
            {page}
          </div>
        </li>
      );
    };

    let onNextClick = () => {
      return enableNext && pageUp();
    };

    const makePager = (maxPage, currentPage) => {
      if (maxPage <= 6) {
        return Array.from(Array(maxPage), (_, i) =>
          generateButton(i + 1, i + 1 === currentPage, i)
        );
      }

      const ellipsisLeft = currentPage > 3;
      const ellipsisRight = currentPage < maxPage - 2;
      const start = ellipsisLeft
        ? ellipsisRight
          ? currentPage - 1
          : maxPage - 4
        : 3;
      const visablePages = [
        1,
        ellipsisLeft ? "..." : 2,
        start,
        start + 1,
        start + 2,
        ellipsisRight ? "..." : maxPage - 1,
        maxPage,
      ];

      return visablePages.map((el, i) =>
        generateButton(el, el === currentPage, i, el === "...")
      );
    };

    const paginationList = makePager(maxPage, activePage);

    if (!showPager) {
      return <div></div>;
    }

    return (
      <nav aria-label="Page navigation" className={css.pagerNav}>
        <ul className={css.pagerList}>
          <li key="previous">
            <Button
              appearance="transparent"
              icon={<ChevronLeft />}
              onClick={onPreviousClick}
            />
          </li>
          {paginationList}
          <li key="next">
            <Button
              appearance="transparent"
              icon={<ChevronRight />}
              onClick={onNextClick}
            />
          </li>
        </ul>
      </nav>
    );
  }
}

const Pager = connectTeamsTheme(PagerTemplate);
export { Pager };
